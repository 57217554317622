<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row class="d-flex flex-nowrap">
          <v-col>
            <h3>{{ $t("MENU.FRONTENT_PAGE_EDITOR") }}</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row v-on:mouseleave="handleLeaveNewsBoxes()">
          <v-col class="" cols="12">
            <v-row>
              <v-col class="" cols="12" sm="12" md="6">
                <div
                  class="news-box box-h8"
                  v-on:mouseover="
                    setAllInactive();
                    setBoxStatus(0, 'active');
                  "
                  v-on:mouseleave="setBoxStatus(0, 'inactive')"
                  @click="handleSelectNews(0)"
                  :class="[isActiveNews[0], { selected: isSelected(0) }]"
                >
                  <!-- 1a -->
                  <v-btn
                    v-if="
                      news[0].id &&
                      isActiveNews[0] == 'active' &&
                      selectedNews.length == 0
                    "
                    @click.stop="handleEditNews(news[0].id)"
                    color="primary"
                    class="mr-2 icon-edit-news"
                    fab
                    x-small
                    dark
                  >
                    <v-icon style="margin-left: 3px">flaticon2-sheet</v-icon>
                  </v-btn>

                  <div v-if="isActiveNews[0] == 'active'" class="icon-bar">
                    <v-btn
                      v-if="!isSelected(0) && selectedNews.length == 1"
                      @click.stop="handleChangeNews(0)"
                      color="primary"
                      class="mr-2 icon"
                      fab
                      x-small
                      dark
                    >
                      <v-icon>flaticon-more-v4</v-icon>
                    </v-btn>

                    <v-btn
                      v-if="selectedNews.length == 0"
                      color="primary"
                      class="mr-2 icon"
                      fab
                      x-small
                      dark
                      @click.stop="handleOpenModalAction(0, 'create')"
                    >
                      <v-icon>flaticon2-add</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="selectedNews.length == 0"
                      color="primary"
                      class="mr-3"
                      fab
                      x-small
                      dark
                      @click.stop="handleOpenModalAction(0, 'update')"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      v-if="selectedNews.length == 0"
                      color="primary"
                      fab
                      x-small
                      dark
                      @click.stop="handleDeleteNews(0)"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                  <v-img max-height="450" :src="news[0].image"></v-img>
                  #{{ news[0].categoryName }}
                  <h3>{{ news[0].name }}</h3>
                  <p>{{ removeTags(news[0].short) }}</p>
                  <p>{{ news[0].description }}</p>
                </div>
              </v-col>
              <v-col class="" cols="12" sm="12" md="6">
                <v-row>
                  <v-col class="" cols="12" sm="6" md="6">
                    <div
                      class="news-box box-h2"
                      v-on:mouseover="
                        setAllInactive();
                        setBoxStatus(3, 'active');
                      "
                      v-on:mouseleave="setBoxStatus(3, 'inactive')"
                      @click="handleSelectNews(3)"
                      :class="[isActiveNews[3], { selected: isSelected(3) }]"
                    >
                      <!-- 4b -->
                      <v-btn
                        v-if="
                          news[3].id &&
                          isActiveNews[3] == 'active' &&
                          selectedNews.length == 0
                        "
                        @click.stop="handleEditNews(news[3].id)"
                        color="primary"
                        class="mr-2 icon-edit-news"
                        fab
                        x-small
                        dark
                      >
                        <v-icon style="margin-left: 3px"
                          >flaticon2-sheet</v-icon
                        >
                      </v-btn>
                      <div v-if="isActiveNews[3] == 'active'" class="icon-bar">
                        <v-btn
                          v-if="!isSelected(3) && selectedNews.length == 1"
                          @click.stop="handleChangeNews(3)"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                        >
                          <v-icon> flaticon-more-v4</v-icon>
                        </v-btn>

                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(3, 'create')"
                        >
                          <v-icon>flaticon2-add</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-3"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(3, 'update')"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          fab
                          x-small
                          dark
                          @click.stop="handleDeleteNews(3)"
                        >
                          <v-icon>mdi-delete </v-icon>
                        </v-btn>
                      </div>
                      #{{ news[3].categoryName }}
                      <h3>{{ news[3].name }}</h3>
                      <p>{{ removeTags(news[3].short) }}</p>
                    </div>
                    <div
                      class="news-box box-h2"
                      v-on:mouseover="
                        setAllInactive();
                        setBoxStatus(4, 'active');
                      "
                      v-on:mouseleave="setBoxStatus(4, 'inactive')"
                      @click="handleSelectNews(4)"
                      :class="[isActiveNews[4], { selected: isSelected(4) }]"
                    >
                      <v-btn
                        v-if="
                          news[4].id &&
                          isActiveNews[4] == 'active' &&
                          selectedNews.length == 0
                        "
                        @click.stop="handleEditNews(news[4].id)"
                        color="primary"
                        class="mr-2 icon-edit-news"
                        fab
                        x-small
                        dark
                      >
                        <v-icon style="margin-left: 3px"
                          >flaticon2-sheet</v-icon
                        >
                      </v-btn>
                      <div v-if="isActiveNews[4] == 'active'" class="icon-bar">
                        <v-btn
                          v-if="!isSelected(4) && selectedNews.length == 1"
                          @click.stop="handleChangeNews(4)"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                        >
                          <v-icon> flaticon-more-v4</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(4, 'create')"
                        >
                          <v-icon>flaticon2-add</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-3"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(4, 'update')"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          fab
                          x-small
                          dark
                          @click.stop="handleDeleteNews(4)"
                        >
                          <v-icon>mdi-delete </v-icon>
                        </v-btn>
                      </div>
                      <!-- 5b -->
                      #{{ news[4].categoryName }}
                      <h3>{{ news[4].name }}</h3>
                      <p>{{ removeTags(news[4].short) }}</p>
                    </div>
                    <div
                      class="news-box box-h4"
                      v-on:mouseover="
                        setAllInactive();
                        setBoxStatus(1, 'active');
                      "
                      v-on:mouseleave="setBoxStatus(1, 'inactive')"
                      @click="handleSelectNews(1)"
                      :class="[isActiveNews[1], { selected: isSelected(1) }]"
                    >
                      <!-- 1a -->
                      <v-btn
                        v-if="
                          news[1].id &&
                          isActiveNews[1] == 'active' &&
                          selectedNews.length == 0
                        "
                        @click.stop="handleEditNews(news[1].id)"
                        color="primary"
                        class="mr-2 icon-edit-news"
                        fab
                        x-small
                        dark
                      >
                        <v-icon style="margin-left: 3px"
                          >flaticon2-sheet</v-icon
                        >
                      </v-btn>
                      <div v-if="isActiveNews[1] == 'active'" class="icon-bar">
                        <v-btn
                          v-if="!isSelected(1) && selectedNews.length == 1"
                          @click.stop="handleChangeNews(1)"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                        >
                          <v-icon> flaticon-more-v4</v-icon>
                        </v-btn>

                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(1, 'create')"
                        >
                          <v-icon>flaticon2-add</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-3"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(1, 'update')"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          fab
                          x-small
                          dark
                          @click.stop="handleDeleteNews(1)"
                        >
                          <v-icon>mdi-delete </v-icon>
                        </v-btn>
                      </div>

                      <v-img max-height="450" :src="news[1].image"></v-img>
                      #{{ news[1].categoryName }}
                      <h3>{{ news[1].name }}</h3>
                      <p>{{ removeTags(news[1].short) }}</p>
                    </div>
                  </v-col>
                  <v-col class="" cols="12" sm="6" md="6">
                    <div
                      class="news-box box-h1"
                      v-on:mouseover="
                        setAllInactive();
                        setBoxStatus(6, 'active');
                      "
                      v-on:mouseleave="setBoxStatus(6, 'inactive')"
                      @click="handleSelectNews(6)"
                      :class="[isActiveNews[6], { selected: isSelected(6) }]"
                    >
                      <v-btn
                        v-if="
                          news[6].id &&
                          isActiveNews[6] == 'active' &&
                          selectedNews.length == 0
                        "
                        @click.stop="handleEditNews(news[6].id)"
                        color="primary"
                        class="mr-2 icon-edit-news"
                        fab
                        x-small
                        dark
                      >
                        <v-icon style="margin-left: 3px"
                          >flaticon2-sheet</v-icon
                        >
                      </v-btn>
                      <div v-if="isActiveNews[6] == 'active'" class="icon-bar">
                        <v-btn
                          v-if="!isSelected(6) && selectedNews.length == 1"
                          @click.stop="handleChangeNews(6)"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                        >
                          <v-icon> flaticon-more-v4</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(6, 'create')"
                        >
                          <v-icon>flaticon2-add</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-3"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(6, 'update')"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          fab
                          x-small
                          dark
                          @click.stop="handleDeleteNews(6)"
                        >
                          <v-icon>mdi-delete </v-icon>
                        </v-btn>
                      </div>
                      <!-- 7c -->
                      #{{ news[6].categoryName }}
                      <h3>{{ news[6].name }}</h3>
                    </div>
                    <div
                      class="news-box box-h4"
                      v-on:mouseover="
                        setAllInactive();
                        setBoxStatus(2, 'active');
                      "
                      v-on:mouseleave="setBoxStatus(2, 'inactive')"
                      @click="handleSelectNews(2)"
                      :class="[isActiveNews[2], { selected: isSelected(2) }]"
                    >
                      <v-btn
                        v-if="
                          news[2].id &&
                          isActiveNews[2] == 'active' &&
                          selectedNews.length == 0
                        "
                        @click.stop="handleEditNews(news[2].id)"
                        color="primary"
                        class="mr-2 icon-edit-news"
                        fab
                        x-small
                        dark
                      >
                        <v-icon style="margin-left: 3px"
                          >flaticon2-sheet</v-icon
                        >
                      </v-btn>
                      <div v-if="isActiveNews[2] == 'active'" class="icon-bar">
                        <v-btn
                          v-if="!isSelected(2) && selectedNews.length == 1"
                          @click.stop="handleChangeNews(2)"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                        >
                          <v-icon> flaticon-more-v4</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(2, 'create')"
                        >
                          <v-icon>flaticon2-add</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-3"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(2, 'update')"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          fab
                          x-small
                          dark
                          @click.stop="handleDeleteNews(2)"
                        >
                          <v-icon>mdi-delete </v-icon>
                        </v-btn>
                      </div>
                      <!-- 3a -->
                      <v-img max-height="450" :src="news[2].image"></v-img>
                      #{{ news[2].categoryName }}
                      <h3>{{ news[2].name }}</h3>
                      <p>{{ removeTags(news[2].short) }}</p>
                    </div>
                    <div
                      class="news-box box-h2"
                      v-on:mouseover="
                        setAllInactive();
                        setBoxStatus(5, 'active');
                      "
                      v-on:mouseleave="setBoxStatus(5, 'inactive')"
                      @click="handleSelectNews(5)"
                      :class="[isActiveNews[5], { selected: isSelected(5) }]"
                    >
                      <!-- bb -->
                      <v-btn
                        v-if="
                          news[5].id &&
                          isActiveNews[5] == 'active' &&
                          selectedNews.length == 0
                        "
                        @click.stop="handleEditNews(news[5].id)"
                        color="primary"
                        class="mr-2 icon-edit-news"
                        fab
                        x-small
                        dark
                      >
                        <v-icon style="margin-left: 3px"
                          >flaticon2-sheet</v-icon
                        >
                      </v-btn>
                      <div v-if="isActiveNews[5] == 'active'" class="icon-bar">
                        <v-btn
                          v-if="!isSelected(5) && selectedNews.length == 1"
                          @click.stop="handleChangeNews(6)"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                        >
                          <v-icon> flaticon-more-v4</v-icon>
                        </v-btn>

                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(5, 'create')"
                        >
                          <v-icon>flaticon2-add</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-3"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(5, 'update')"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          fab
                          x-small
                          dark
                          @click.stop="handleDeleteNews(5)"
                        >
                          <v-icon>mdi-delete </v-icon>
                        </v-btn>
                      </div>
                      #{{ news[5].categoryName }}
                      <h3>{{ news[5].name }}</h3>
                      <p>{{ removeTags(news[5].short) }}</p>
                    </div>
                    <div
                      class="news-box box-h1"
                      v-on:mouseover="
                        setAllInactive();
                        setBoxStatus(7, 'active');
                      "
                      v-on:mouseleave="setBoxStatus(7, 'inactive')"
                      @click="handleSelectNews(7)"
                      :class="[isActiveNews[7], { selected: isSelected(7) }]"
                    >
                      <!-- 8c -->
                      <v-btn
                        v-if="
                          news[7].id &&
                          isActiveNews[7] == 'active' &&
                          selectedNews.length == 0
                        "
                        @click.stop="handleEditNews(news[7].id)"
                        color="primary"
                        class="mr-2 icon-edit-news"
                        fab
                        x-small
                        dark
                      >
                        <v-icon style="margin-left: 3px"
                          >flaticon2-sheet</v-icon
                        >
                      </v-btn>
                      <div v-if="isActiveNews[7] == 'active'" class="icon-bar">
                        <v-btn
                          v-if="!isSelected(7) && selectedNews.length == 1"
                          @click.stop="handleChangeNews(7)"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                        >
                          <v-icon> flaticon-more-v4</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-2 icon"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(7, 'create')"
                        >
                          <v-icon>flaticon2-add</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          class="mr-3"
                          fab
                          x-small
                          dark
                          @click.stop="handleOpenModalAction(7, 'update')"
                        >
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="selectedNews.length == 0"
                          color="primary"
                          fab
                          x-small
                          dark
                          @click.stop="handleDeleteNews(7)"
                        >
                          <v-icon>mdi-delete </v-icon>
                        </v-btn>
                      </div>
                      <!-- 7c -->
                      #{{ news[7].categoryName }}
                      <h3>{{ news[7].name }}</h3>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-dialog v-model="modalDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Törlés</v-card-title>
            <v-card-text class="headline"
              >Biztos törölni szeretnéd a címlapról?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="handleCloseDeleteModal()">{{
                $t("FORMS.cancel")
              }}</v-btn>
              <v-btn color="primary" @click="deleteItemConfirm()">OK</v-btn>

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="modalAction" max-width="650px">
          <v-card>
            <v-card-title class="headline">{{ modalActionTitle }}</v-card-title>
            <v-card-text class="headline">
              <v-autocomplete
                item-text="name"
                item-value="id"
                v-model="selectedNewsId"
                :items="computedNewsCollection"
                label="Hírek"
              ></v-autocomplete>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="handleCloseModalAction()">{{
                $t("FORMS.cancel")
              }}</v-btn>
              <v-btn color="primary" @click="handleAcceptModalAction()"
                >OK</v-btn
              >

              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SiteService from "@/core/services/site.service.js";

import ApiService from "@/core/services/api.service";

//export const PERMISSION_TO = "news.frontPageEditor.menu";

export default {
  name: "News",

  data() {
    return {
      cardTitle: "Címlap szerkesztő",
      isActiveNews: [],
      selectedNews: [],
      indexToDelete: null,
      modalDelete: false,
      modalAction: false,
      modalActionType: "create",
      modalActionTitle: "",
      defaultNews: [
        {
          name: "Képzés egységes elvek mentén",
          image:
            "https://www.utanpotlassport.hu/wp-content/uploads/2022/10/kézibővi_511x365_acf_cropped_511x365_acf_cropped-300x214.jpeg",
          short:
            "A Kozma István Magyar Birkózó Akadémia szabadfogású növendékei remekeltek a Krasnikban rendezett korosztályos nemzetközi viadalon. A csepeli fiatalok 11 éremmel, közte két arannyal tértek haza Lengyelországból.",
          description:
            "A nyári szezonból nem csak a világversenyek eredményei eycsapatok közül a juniorok és az ifik vb-n szereztek érmeket, míg a serdülők az EYOF-ot nyerték meg, és kitett magáért az U18-as Eb-n negyedik és az U20-szakmai működési feltételeinek biztosítása, a sporttudományi területek kiaknázása, illetve .",
        },
        {
          name: "Éremeső Lengyelországban",
          image:
            "https://www.utanpotlassport.hu/wp-content/uploads/2022/10/kézibővi_511x365_acf_cropped_511x365_acf_cropped-300x214.jpeg",
          short:
            "A Magyar Kézilabda Szövetség nyáron kinevezett sportszakmai igazgatóját, Juhász Istvánt kérdeztük az utánpótlást érintő szervezeti változásokról.",
          description:
            "A lengyelországi Krasnikban szerepeltek a Kozma István-akadémia birkózói U20-as és U17-es szabadfogású nemzetközi versenyen, amelyen összesen 2 arany-, 3 ezüst- és 6 bronzérmet viadalon mintegy 300 sportoló mérte össze a tudását egymással, a házigazdák és a magyarok mellett cseh, litván, lett, szlovák és ukrán sportolók léptek szőnyegre.",
        },

        {
          name: "Míg a serdülők az EYOF-ot nyerték meg",
          image:
            "https://www.utanpotlassport.hu/wp-content/uploads/2022/10/kézibővi_511x365_acf_cropped_511x365_acf_cropped-300x214.jpeg",
          short:
            "Krasnikban rendezett korosztályos nemzetközi viadalon.A Kozma István Magyar Birkózó Akadémia szabadfogású növendékei remekeltek a Krasnikban rendezett korosztályos nemzetközi viadalon. A csepeli fiatalok 11 éremmel, közte két arannyal tértek haza Lengyelországból.",
          description:
            "A nyári szezonból nem csak a világversenyek eredményei eycsapatok közül a juniorok és az ifik vb-n szereztek érmeket, míg a serdülők az EYOF-ot nyerték meg, és kitett magáért az U18-as Eb-n negyedik és az U20-szakmai működési feltételeinek biztosítása, a sporttudományi területek kiaknázása, illetve .",
        },
        {
          name: "Akadémia szabadfogású növendékei remekeltek",
          image:
            "https://www.utanpotlassport.hu/wp-content/uploads/2022/10/kézibővi_511x365_acf_cropped_511x365_acf_cropped-300x214.jpeg",
          short:
            "Krasnikban rendezett korosztályos nemzetközi viadalon.A Kozma István Magyar Birkózó Akadémia szabadfogású növendékei remekeltek a Krasnikban rendezett korosztályos nemzetközi viadalon. A csepeli fiatalok 11 éremmel, közte két arannyal tértek haza Lengyelországból.",
          description:
            "A nyári szezonból nem csak a világversenyek eredményei eycsapatok közül a juniorok és az ifik vb-n szereztek érmeket, míg a serdülők az EYOF-ot nyerték meg, és kitett magáért az U18-as Eb-n negyedik és az U20-szakmai működési feltételeinek biztosítása, a sporttudományi területek kiaknázása, illetve .",
        },
        {
          name: "A Kozma István Magyar Birkózó Akadémia szabadfogású",
          image:
            "https://www.utanpotlassport.hu/wp-content/uploads/2022/10/kézibővi_511x365_acf_cropped_511x365_acf_cropped-300x214.jpeg",
          short:
            "A juniorok és az ifik vb-n szereztek érmeket, míg a serdülők az EYOF-ot",
          description:
            "A nyári szezonból nem csak a világversenyek eredményei eycsapatok közül a juniorok és az ifik vb-n szereztek érmeket, míg a serdülők az EYOF-ot nyerték meg, és kitett magáért az U18-as Eb-n negyedik és az U20-szakmai működési feltételeinek biztosítása, a sporttudományi területek kiaknázása, illetve .",
        },
        {
          name: "A kevesek egyike",
          image:
            "https://www.utanpotlassport.hu/wp-content/uploads/2022/10/image-2022-10-07T144158.622-300x178.png",
          short:
            "A háromszoros felnőttbajnok, U20-as Eb-bronzérmes többpróbázó, Szűcs Szabina nem teljesen elégedett az idei teljesítményével, de bízik benne, hogy nemsokára ki tudja hozni magából a legtöbbet.",
          description:
            "A téli és a tavaszi felkészülésem is jól sikerült, és vártam a nyári versenyeket – mondja a Zsivoczky Attila által edzett többpróbázó. – Úgy éreztem, hogy sokat fejlődtem, azonban lelkileg és",
        },
        {
          name: "Még önmagát is meglepte",
          image:
            "https://www.utanpotlassport.hu/wp-content/uploads/2022/10/99_whun_1506-300x200.jpg",
          short:
            "A VBW CEKK Cegléd 19 éves kosárlabdázója, Rakita Lili nagyszerű formában kezdte az évadot a felnőttélvonalbeli bajnokságban. Az első három fordulóban több mint tíz pontot, és majdnem húszas VAL-mutatót átlagolt, miközben a legutóbbi, Soproni elleni meccsen csapata legjobbjaként zárt.",
          description:
            "Nemcsak a külső szemlélőnek tűnhet váratlannak a jó teljesítmény, hanem saját magam számára is pozitív meglepetés. Önmagában ilyen sok játékpercre sem számítottam – kezdte Rakita az Utánpótlássportnak",
        },
        {
          name: "Úszás: jövőre még erősebb lehet a leányválogatott, a főszereplők utánpótláskorúak maradnak",
          image:
            "https://www.utanpotlassport.hu/wp-content/uploads/2022/10/99_whun_1506-300x200.jpg",
          short:
            "A VBW CEKK Cegléd 19 éves kosárlabdázója, Rakita Lili nagyszerű formában kezdte az évadot a felnőttélvonalbeli bajnokságban. Az első három fordulóban több mint tíz pontot, és majdnem húszas VAL-mutatót átlagolt, miközben a legutóbbi, Soproni elleni meccsen csapata legjobbjaként zárt.",
          description:
            "Nemcsak a külső szemlélőnek tűnhet váratlannak a jó teljesítmény, hanem saját magam számára is pozitív meglepetés. Önmagában ilyen sok játékpercre sem számítottam – kezdte Rakita az Utánpótlássportnak",
        },
      ],
      newsCollection: [],
      frontPage: { news: [], editor: { length: 8 } },
      selectedNewsId: null,
      selectType: "a",
    };
  },

  computed: {
    ...mapGetters([
      "newsCategoryCollection",
      "statuses",
      "getNewsCategoryByID",
    ]),

    news() {
      let news = [];
      if (
        this.frontPage ||
        this.frontPage.news ||
        this.frontPage.news.length != 0
      ) {
        news = this.frontPage.news;
      }

      let rules = this.frontPage.editor.rules;
      let collection = news.map((item, index) => {
        if (!item || !item.translations || !item.translations.hu) {
          return null;
        }

        let newsItem = {};
        let translation = item.translations.hu;
        newsItem.id = item.id;
        newsItem.name = translation.alternative_title;
        newsItem.short = translation.intro;

        if (rules) {
          let customFields = translation.custom_fields;

          newsItem.image = this.getImage(
            customFields,
            this.frontPage.editor.rules[index]["image_priority"]
          );
        }
        let categories = item.categories;

        let categoryName = null;

        if (categories && categories.length > 0) {
          let translations = this.getNewsCategoryByID(
            categories[0]
          ).translations;
          categoryName = this.$helpers.getTranslated(translations).name;
        }

        newsItem.categoryName = categoryName;
        return newsItem;
      });

      let len = this.frontPage.editor.length + 1 - collection.length;
      for (var i = 0; i < len; i++) {
        collection.push({ name: "", short: "", image: "", categoryName: "" });
      }
      // console.log(collection);

      return collection;
    },

    computedNewsCollection() {
      if (!this.newsCollection) return [];
      let collection = this.newsCollection.map((item) => {
        let newsItem = {};

        let categories = item.categories;

        let categoryName = null;

        if (categories && categories.length > 0) {
          let translations = this.getNewsCategoryByID(
            categories[0]
          ).translations;
          categoryName = this.$helpers.getTranslated(translations).name + " | ";
        }

        newsItem.name =
          categoryName + this.$helpers.getTranslated(item.translations).name;

        newsItem.id = item.id;

        return newsItem;
      });

      return collection.filter(
        (item) => !this.news.map((i) => i.id).includes(item.id)
      );
    },
  },

  methods: {
    ...mapActions(["fetchNewsCategory", "fetchUser"]),

    setBoxStatus(index, status) {
      this.$set(this.isActiveNews, index, status);
    },

    resetStatuses() {
      for (let i = 0; i < 15; i++) {
        this.$set(this.isActiveNews, i, "");
      }
    },

    setAllInactive() {
      for (let i = 0; i < 15; i++) {
        this.$set(this.isActiveNews, i, "inactive");
      }
    },

    handleLeaveNewsBoxes() {
      if (
        this.isActiveNews.every((item) => {
          return item == "inactive" || item == "";
        })
      ) {
        this.resetStatuses();
      }
    },

    isSelected(index) {
      return this.selectedNews.indexOf(index) > -1;
    },

    handleSelectNews(index) {
      let indexOfSelected = this.selectedNews.indexOf(index);

      if (indexOfSelected == -1) {
        if (this.selectedNews.length == 1) {
          return null;
        }
        this.selectedNews.push(index);
      } else {
        this.selectedNews.splice(indexOfSelected, 1);
      }
    },

    handleChangeNews(index) {
      ApiService.post(
        "news/admin/" +
          SiteService.getActiveSiteId() +
          "/news-front-page-editor/change",
        {
          pos1: this.selectedNews[0],
          pos2: index,
        }
      ).then(() => {});
      this.selectedNews = [];
      this.isActiveNews = [];
      this.handleCloseDeleteModal();
      this.fetchModel();
    },

    handleDeleteNews(index) {
      this.indexToDelete = +index;
      this.modalDelete = true;
    },

    deleteItemConfirm() {
      ApiService.post(
        "news/admin/" +
          SiteService.getActiveSiteId() +
          "/news-front-page-editor/remove",
        {
          pos: this.indexToDelete,
        }
      ).then(() => {});
      this.handleCloseDeleteModal();
      this.fetchModel();
    },

    handleCloseDeleteModal() {
      this.indexToDelete = null;
      this.modalDelete = false;
    },

    handleOpenModalAction(index, action) {
      this.modalActionIndex = index;
      this.modalAction = true;
      this.modalActionType = action;
      if (this.modalActionType == "create") {
        this.modalActionTitle = "Új hír hozzáadása";
      }
      if (this.modalActionType == "update") {
        this.modalActionTitle = "Hír szerkesztés";
      }
    },

    handleCloseModalAction() {
      this.modalActionIndex = null;
      this.modalAction = false;
      this.modalActionTitle = "";
    },

    handleAcceptModalAction() {
      let url =
        "news/admin/" +
        SiteService.getActiveSiteId() +
        "/news-front-page-editor/modify";
      if (this.modalActionType == "create") {
        url =
          "news/admin/" +
          SiteService.getActiveSiteId() +
          "/news-front-page-editor/insert";
      }
      ApiService.post(url, {
        pos: this.modalActionIndex,
        news_id: this.selectedNewsId,
      }).then(() => {});

      this.handleCloseModalAction();
      this.fetchModel();
    },

    fetchNews() {
      return new Promise((resolve) => {
        ApiService.get(
          "news/admin/" +
            SiteService.getActiveSiteId() +
            "/news-front-page-editor/get-news"
        ).then(({ data }) => {
          this.newsCollection = data;
          resolve();
        });
      });
    },

    fetchFrontPageNews() {
      return new Promise((resolve) => {
        ApiService.get(
          "news/admin/" +
            SiteService.getActiveSiteId() +
            "/news-front-page-editor/"
        ).then(({ data }) => {
          this.frontPage = data;
          resolve();
        });
      });
    },

    removeTags(string) {
      if (!string) return "";

      return string.replace(/<[^>]+>/gi, "");
    },

    getImage(customFields, imageTypes) {
      let image = "";
      if (!imageTypes) {
        return image;
      }

      imageTypes.forEach((type) => {
        if (
          customFields[type] &&
          customFields[type][0] &&
          customFields[type][0]["path"]
        ) {
          if (image == "") {
            image =
              process.env.VUE_APP_BACKEND_URL +
              "/" +
              customFields[type][0]["path"];
          }
        }
      });

      return image;
    },

    handleEditNews(id) {
      if (!id) return;
      let routeData = this.$router.resolve({
        path: "/cms/news/news/" + id,
        replace: true,
      });
      window.open(routeData.href, "_blank");
    },

    fetchModel() {
      this.fetchNewsCategory().then(() => {
        this.fetchFrontPageNews().then(() => {
          this.fetchNews();
        });
      });
    },
  },

  mounted() {
    this.loadingTable = true;

    this.fetchModel();
  },
};
</script>

<style scoped>
.box-h8 {
  min-height: 48rem;
}
.box-h4 {
  min-height: 24rem;
}
.box-h2 {
  min-height: 12rem;
}
.box-h1 {
  min-height: 6rem;
}
/*
.latestActive {
  } */

/* top: 1rem; */
.icon-bar {
  position: absolute;
  right: 1rem;
  /* bottom: 1rem; */
  padding: 10px;

  z-index: 9999;
}

.inactive {
  background-color: #fff;
  opacity: 0.5;
}

.selected {
  opacity: 1;

  background-color: #f3f6f9;
}

.icon-edit-news {
  position: absolute;
  left: 2rem;
  /* top: 1rem; */
  margin-top: 10px;

  z-index: 9999;
}
</style>
